import * as React from 'react';
import { useContext, useEffect, useState } from 'react'


import { createTheme, ThemeProvider } from '@mui/material/styles';

import Container from '../../../../components/components-ui/Container/Container'
//import { Grid, Col } from '../../../../components/Grid'
import { Formik, Form, Field } from 'formik'
import Button from '../../../../components/components-ui/Button/Button'
import styles from './indicador.module.css'
import DataTable from 'react-data-table-component';
import api from '../../../../services/Api'

import { BlockScreenLoading } from '../../../../components/BlockScreenLoading'

import { styled } from '@mui/material/styles';
import ContainerM from '@mui/material/Container';
import Box from '@mui/material/Box';
import { ToastContainer, toast } from 'react-toastify'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ReactSelect from 'react-select'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';




import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DatePicker } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
//import { DemoContainer } from '@mui/x-date-pickers/internals/demo';



import Autocomplete from '@mui/material/Autocomplete';


const segmentos = [
    { label: 'GRANEL', Id: 3 },
    { label: 'GARRAFA', Id: 2 },
    { label: 'GRANDES REDES', Id: 1 }]



function IndicadorSegmento() {
    const [idSegmento, setIdSegmento] = useState([])
    const [pending, setPending] = useState(true);
    const [Data, setData] = useState([])
    const [relatorio, setRelatorio] = useState([])
    const [Veiculo, setVeiculo] = useState([])
    const [IdVeiculo, setIdVeiculo] = useState('')
    const [DataInicial, setDataInicial] = useState(dayjs('2023-02-01'))
    const [DataFinal, setDataFinal] = useState(dayjs('2023-02-28'))

    const [dataAtualServidor, setDataAtualServidor] = useState('')
    const [blockScreen, setBlockScreen] = useState(false)

    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const [valueMesAno, setValueMesAno] = useState(dayjs('2023-03-01'));




    async function getIndicador() {
        try {
            // const res_vei = await api.get(`/veiculo`)
            // //console.log(res_vei.data)
            // setVeiculo(res_vei.data)



            const response = await api.post(`/relatorio/indicadorsegmento`, { 'mes': valueMesAno.$M + 1, 'ano': valueMesAno.$y, 'id_segmento': value?.Id })

            setRelatorio(response.data)

        } catch (error) {
            console.log(error)
        }
    }




    function getRelatorio() {

        console.log(value)
        if (value?.Id == null) {
            showAlert(1, 'Informe o segmento')
            return
        }

        getIndicador()


    }

    // async function getMesAno() {
    //     const response = await api.get(`/mesano`)
    //     setMesAno(response.data)
    // }






    useEffect(() => {

        //console.log(dayjs(DataInicial).format('YYYY-MM-DD'))

        //getIndicador()

    }, [])


    const CustomLoader = () => (
        <div style={{ padding: '24px' }}>

            <div>Carregando...</div>
        </div>
    );



    const conditionalRowStyles = [
        {
            when: row => row.Tipo === 'RESULTADO',
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ]


    const columns = [
        {
            name: 'Estabelecimento',
            selector: row => row.Estabelecimento,
            id: 'Tipo',
            width: "200px",
            sortable: true,
        },
        {
            id: 'VolumeColetado',
            name: 'Vol. Pesado',
            selector: row => row.VolumeColetado,
            width: "100px",
            sortable: true,
            right: true,
            grow: 1,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.VolumeColetado)
            },

        },
        {
            //id: 'EstimativaMotorista',
            name: 'Qt Deveria esta',
            right: true,
            width: "130px",
            grow: 1,
            // selector: row => row.EstimativaMotorista,
            // format: (row, index) => {
            //     return Intl.NumberFormat('pt-BR').format(row.EstimativaMotorista)
            // },
            // sortable: true,
            // conditionalCellStyles: [
            //     {
            //         when: row => row.Granel < 0 && row.Tipo === 'RESULTADO',
            //         style: {
            //             backgroundColor: 'rgba(242, 38, 19, 0.9)',
            //             color: 'white',
            //             // '&:hover': {
            //             //     cursor: 'pointer',
            //             // },
            //         },
            //     },
            // ],

        },
        {
            //id: 'PesoUsina',
            name: 'Balanço',
            right: true,
            // selector: row => row.PesoUsina,
            // format: (row, index) => {
            //     return Intl.NumberFormat('pt-BR').format(row.PesoUsina)
            // },
            sortable: true,
            width: "120px",
        },

        {
            // id: 'PesoResiduo',
            name: 'Meta',
            right: true,
            //selector: row => row.PesoResiduo,
            width: "150px",
            // format: (row, index) => {
            //     return Intl.NumberFormat('pt-BR').format(row.PesoResiduo)
            // },
            sortable: true,
        },

        {
            id: 'QtdEstabelecimentoEmOperacao',
            name: 'Qt Operação',
            right: true,
            width: "120px",
            selector: row => row.QtdEstabelecimentoEmOperacao,
            // format: (row, index) => {
            //     return Intl.NumberFormat('pt-BR').format(row.PesoImpureza)
            // },
            sortable: true,
        },

        {
            //id: 'TotalImpureza',
            name: 'Sem Contato',
            right: true,
            //selector: row => row.TotalImpureza,
            width: "120px",
            // format: (row, index) => {
            //     return Intl.NumberFormat('pt-BR').format(row.TotalImpureza)
            // },
            sortable: true,
        },

        {
            id: 'QtdLocalComColeta',
            name: 'Com Coleta',
            right: true,
            width: "130px",
            selector: row => row.QtdLocalComColeta,
            // format: (row, index) => {
            //     return Intl.NumberFormat('pt-BR').format(row.AcuracidadeLigacaoMotorista)
            // },
            sortable: true,
        },

        {
            id: 'QtdLocalSemColeta',
            name: 'Sem Coleta',
            right: true,
            selector: row => row.QtdLocalSemColeta,
            width: "130px",
            // format: (row, index) => {
            //     return Intl.NumberFormat('pt-BR').format(row.AcuracidadeUsinaMotorista)
            // },
            sortable: true,
        },


    ];

    const customStyles = {

        subHeader: {
            style: {
                paddingLeft: '0px', // override the cell padding for data cells
                paddingRight: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '0px',
                paddingRight: '0px',
            },
            draggingStyle: {
                cursor: 'move',
            },
        },
        head: {
            style: {
                paddingLeft: '0px', // override the cell padding for data cells
                paddingRight: '0px',
            },
        },
        header: {
            minHeight: '20px',
            zIndex: 0,
            paddingLeft: '0px', // override the cell padding for data cells
            paddingRight: '0px',
        },
        headRow: {
            style: {
                minHeight: '20px',
                zIndex: 0,
                paddingLeft: '0px', // override the cell padding for data cells
                paddingRight: '0px',
            }
        },
        cells: {
            style: {
                paddingLeft: '0px', // override the cell padding for data cells
                paddingRight: '0px',
            },
        },
        rows: {
            style: {
                minHeight: '20px', // override the row height
                paddingLeft: '0px', // override the cell padding for data cells
                paddingRight: '0px',
            },
        },
    }


    // const handleDateChange = (date) => {
    //     console.log(date);
    //     setValue(new Date(date.getFullYear(), date.getMonth()));
    // };

    function showAlert(notify, msg) {
        if (notify === 0) {
            toast.success(msg)
        } else {
            toast.error(msg)
        }
    }

    return (

        <Container>
            <ToastContainer />
            <BlockScreenLoading loading display={blockScreen} />
            <p>Indicador Por Segmento</p>

            <Grid container className={styles.filtro}>
                <Grid item xs={3} >
                    <FormControl fullWidth>
                        <Autocomplete
                            //disablePortal
                            id="combo-box-demo"
                            options={segmentos}
                            sx={{ width: 300 }}
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                                // console.log(value)
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                                console.log(inputValue)
                            }}
                            renderInput={(params) => <TextField {...params} label="Segmento" />}
                        />
                    </FormControl>


                </Grid>
                <Grid item xs={1} >

                </Grid>

                <Grid item xs={2} >

                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                        <Stack spacing={3}>
                            <DatePicker
                                views={['month', 'year']}
                                label="Mês/Ano"
                                minDate={dayjs('2023-01-01')}
                                maxDate={dayjs('2023-12-01')}
                                value={valueMesAno}
                                onChange={(newValue) => {
                                    setValueMesAno(newValue);

                                }}
                                renderInput={(params) => <TextField {...params} helperText={null} />}
                            />

                        </Stack>
                    </LocalizationProvider>

                </Grid>

                <Grid item xs={1} >

                </Grid>

                <Grid item xs={1} >

                </Grid>

                <Grid item xs={2}>
                    <Button
                        onClick={() => getRelatorio()}
                    //disabled={status}
                    >Gerar Relatório</Button>
                </Grid>


            </Grid >




            <div className={styles.container_grid}>


                <div className={styles.coletas}>

                    <Box sx={{ width: '100%' }}>
                        <DataTable
                            // title="Indicador Diário Janeiro/2023"
                            columns={columns}
                            data={relatorio}
                            // pagination
                            // paginationPerPage={11}
                            //dense
                            //fixedHeader
                            //progressPending={pending}
                            progressComponent={<CustomLoader />}
                            customStyles={customStyles}

                        />
                    </Box>


                </div>


            </div>


        </Container >

    )
}

export default IndicadorSegmento
