import { useContext, useEffect, useState } from 'react'

import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '../../../../components/components-ui/Container/Container'
//import { Grid, Col } from '../../../../components/Grid'
import { Formik, Form, Field } from 'formik'
import Button from '../../../../components/components-ui/Button/Button'
import Button2 from '@mui/material/Button';
import styles from './indicador.module.css'
import DataTable from 'react-data-table-component';
import api from '../../../../services/Api'




import { BlockScreenLoading } from '../../../../components/BlockScreenLoading'

// import { styled } from '@mui/material/styles';
// import ContainerM from '@mui/material/Container';
import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import ReactSelect from 'react-select'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
// import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';


import Modal from '@mui/material/Modal';

import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
// import TextField from '@mui/material/TextField';
// import Stack from '@mui/material/Stack';
// import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import zIndex from '@mui/material/styles/zIndex';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Height } from '@mui/icons-material';
import TextField from '@mui/material/TextField';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, padding: '0' }}>
                    <Typography>{children}</Typography>
                </Box>
            )
            }
        </div >
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function Indicador() {
    const [pending, setPending] = useState(false);
    const [status, setStatus] = useState(false)
    const [VolumeInicioMes, setVolumeInicioMes] = useState(0)
    const [novoVolumeInicioMes, setNovoVolumeInicioMes] = useState(0)
    const [Indicador, setIndicador] = useState([])
    const [Planejado, setPlanejado] = useState([])
    const [Resumo, setResumo] = useState([])
    const [MesAno, setMesAno] = useState(null)
    const [Fmes, setFmes] = useState('')
    const [Fano, setFano] = useState('')
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedAno, setselectedAno] = useState(null);
    const [Data, setData] = useState(null)
    const [dataAtualServidor, setDataAtualServidor] = useState('')
    const [blockScreen, setBlockScreen] = useState(false)



    const [idPlanVolTanque, setIdPlanVolTanque] = useState(0);
    const [idPlanejamento, setIdPlanejamento] = useState(0);
    const [dataPlanejado, setDataPlanejado] = useState(0);
    const [granelPlanejado, setGranelPlanejado] = useState(0);
    const [garrafaPlanejado, setGarrafaPlanejado] = useState(0);
    const [captGranelPlanejado, setCaptGranelPlanejado] = useState(0);
    const [captGarrafaPlanejado, setCaptGarrafaPlanejado] = useState(0);
    const [coletoresPlanejado, setColetoresPlanejado] = useState(0);
    const [sabaoPlanejado, setSabaoPlanejado] = useState(0);
    const [saidaPlanejada, setSaidaPlanejada] = useState(0);
    const [grandesRedesPlanejada, setGrandesRedesPlanejado] = useState(0);







    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    const [openModalTanque, setOpenModalTanque] = useState(false);
    const handleOpenModalTanque = () => setOpenModalTanque(true);
    const handleCloseModalTanque = () => setOpenModalTanque(false);



    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const styleModalTanque = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const meses = [{ value: '1', label: 'Janeiro' }
        , { value: '2', label: 'Fevereiro' }
        , { value: '3', label: 'Março' }
        , { value: '4', label: 'Abril' }
        , { value: '5', label: 'Maio' }
        , { value: '6', label: 'Junho' }
        , { value: '7', label: 'Julho' }
        , { value: '8', label: 'Agosto' }
        , { value: '9', label: 'Setembro' }
        , { value: '10', label: 'Outubro' }
        , { value: '11', label: 'Novembro' }
        , { value: '12', label: 'Dezembro' }
    ]

    const ano = [{ value: '2023', label: '2023' }]



    async function getIndicador() {

        //setData(`{"mes": ${selectedOption},"ano": ${selectedAno}}`)

        //console.log(selectedOption?.value)
        var data2 = new Date();
        var mes = String(data2.getMonth() + 1).padStart(2, '0');
        var ano = data2.getFullYear()
        console.log(`mes: ${mes}`)


        const data = {
            ano: selectedAno?.value == null ? ano : selectedAno?.value,
            mes: selectedOption?.value == null ? mes : selectedOption?.value,
            // ano: Fano,
            // mes: Fmes,
        }

        console.log(data)


        const res_vol_mes = await api.post(`/relatorio/indicador_saldo`, data)
        setVolumeInicioMes(res_vol_mes.data[0]?.SaldoInicialTanque == null ? 0 : res_vol_mes.data[0]?.SaldoInicialTanque)
        setIdPlanVolTanque(res_vol_mes.data[0]?.Id == null ? 0 : res_vol_mes.data[0]?.Id)
        console.log(idPlanVolTanque)



        //console.log(res_vol_mes.data[0].SaldoInicialTanque)

        const response = await api.post(`/relatorio/index`, data)
        setIndicador(response.data)
        //console.log(response.data)

        const resp = await api.post(`/relatorio/indicador_planejado`, data)
        setPlanejado(resp.data)


        const resumo = await api.post(`/relatorio/indicador_resumo`, data)
        setResumo(resumo.data)
        setPending(false);

    }


    function teste() {
        setBlockScreen(true)
        //setData(`{"mes": ${selectedOption},"ano": ${selectedAno}}`)


        setStatus(true)

        getIndicador()

        setStatus(false)

        console.log({ selectedAno })
        console.log({ selectedOption })
        setBlockScreen(false)
    }

    async function getMesAno() {
        const response = await api.get(`/mesano`)
        setMesAno(response.data)
    }

    async function getDataAtualServidor() {
        const response = await api.get(`/getdate`)

        setDataAtualServidor(response.data?.date)

        // console.log(`A data atual é ${response.data?.date}`)


        const mes = dayjs(response.data?.date).format('M')
        const ano = dayjs(response.data?.date).format('YYYY')

        // console.log(`O mês é ${mes}`)
        // console.log(`O ano é ${ano}`)

        setFmes(mes)
        setFano(ano)

        // console.log(Fmes)
        // console.log(Fano)



    }


    function handleChangeMes(value) {
        setselectedAno(value)
    }

    useEffect(() => {


        getDataAtualServidor()

        setselectedAno(Fano)
        setSelectedOption(Fmes)
        getMesAno()
        getIndicador()




    }, [])


    const CustomLoader = () => (
        <div style={{ padding: '24px' }}>
            <div>Carregando...</div>
        </div>
    );



    const conditionalRowStyles = [
        {
            when: row => row.Tipo === 'RESULTADO',
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ]

    function hello(e) {
        handleOpen()
        console.log(e)
        //setName(e.id)
        setIdPlanejamento(e.id)
        setGranelPlanejado(e.granel)
        setGarrafaPlanejado(e.garrafa)
        setCaptGarrafaPlanejado(e.captgarrafa)
        setCaptGranelPlanejado(e.captgranel)
        setSaidaPlanejada(e.saidaplanejada)
        setColetoresPlanejado(e.coletores)
        setGrandesRedesPlanejado(e.grandesredes)
        setSabaoPlanejado(e.sabaoplanejado)
        setDataPlanejado(e.data)

    }


    function edit_volume_tanque(e) {
        if (idPlanVolTanque == 0) {
            showAlert(1, 'Sem planejamento para o mês atual.')
        } else {
            setNovoVolumeInicioMes(VolumeInicioMes)
            handleOpenModalTanque()
        }
    }

    async function salvar_volume_tanque(e) {




        if (novoVolumeInicioMes == VolumeInicioMes) {
            showAlert(1, 'Informe um volume diferente do volume atual')
            return
        }

        if (novoVolumeInicioMes < 0) {
            showAlert(1, 'O volume deve ser um valor positivo.')
            return
        }

        handleCloseModalTanque()
        setBlockScreen(true)
        const response = await api.put(`/planejamento/tanque/volume/${idPlanVolTanque}`, {
            novo_volume_tanque: novoVolumeInicioMes
        })


        if (response.data.error === 0) {

            showAlert(0, response.data.msg)
            handleCloseModalTanque()
            teste()
            setBlockScreen(false)
            return

        }
        setBlockScreen(false)
        showAlert(1, response.data.msg)


    }


    function showAlert(notify, msg) {
        if (notify === 0) {
            toast.success(msg)
        } else {
            toast.error(msg)
        }
    }


    async function EditarPlanejamento(values) {
        try {

            if (idPlanejamento === null) {
                const response = await api.post(`/planejamento/create`, {
                    granel_pla: granelPlanejado,
                    capt_granel_pla: captGranelPlanejado,
                    garrafa_pla: garrafaPlanejado,
                    capt_garrafa_pla: captGarrafaPlanejado,
                    coletores_pla: coletoresPlanejado,
                    grandes_redes_pla: grandesRedesPlanejada,
                    sabao_pla: sabaoPlanejado,
                    saida_planejada: saidaPlanejada
                })


                if (response.data.error === 0) {
                    showAlert(0, response.data.msg)
                    handleClose()
                    getIndicador()
                    return
                }

                showAlert(1, response.data.msg)

            } else {
                const response = await api.put(`/planejamento/edit/${idPlanejamento}`, {
                    granel_pla: granelPlanejado,
                    capt_granel_pla: captGranelPlanejado,
                    garrafa_pla: garrafaPlanejado,
                    capt_garrafa_pla: captGarrafaPlanejado,
                    coletores_pla: coletoresPlanejado,
                    grandes_redes_pla: grandesRedesPlanejada,
                    sabao_pla: sabaoPlanejado,
                    saida_planejada: saidaPlanejada
                })


                if (response.data.error === 0) {
                    showAlert(0, response.data.msg)
                    handleClose()
                    getIndicador()
                    return
                }

                showAlert(1, response.data.msg)
            }

        } catch (error) {
            showAlert(1, error)
        }

    }

    const columns = [
        {

            name: 'TIPO',
            selector: row => row.Tipo,
            id: 'Tipo',
            width: "78px",



        },
        {
            cell: (row, index, column, id) => {
                if (row.Tipo === 'DINAMICO') {
                    return (
                        <IconButton sx={{ paddingTop: 0, paddingBottom: 0 }} onClick={() => hello({
                            id: row.IdPlanejamento,
                            granel: row.GranelPlanejado,
                            garrafa: row.GarrafaPlanejado,
                            captgarrafa: row.CapGarrPlanejado,
                            captgranel: row.CaptGrPlanejado,
                            grandesredes: row.GdesRedesPlanejado,
                            coletores: row.ColetoresPlanjeado,
                            saidaplanejada: row.SaidaPlanejada,
                            sabaoplanejado: row.Sabao,
                            data: row.Data
                        })}>
                            <MoreVertIcon sx={{ fontSize: 15, color: '#f44336f7' }} />
                        </IconButton >)
                } else {
                    return ''
                }
            }
            ,

            width: '30px', // custom width for icon button
            // style: {
            //     borderBottom: '1px solid #FFFFFF',
            //     marginBottom: '-1px',
            // },
            selector: row => row.Tipo,

        },

        {
            name: 'DATA',
            selector: row => row.Data,
            width: "50px",
        },
        {
            name: 'GRANEL',
            right: true,
            width: "60px",
            grow: 1,
            selector: row => row.Granel,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.Granel)
            },
            conditionalCellStyles: [
                {
                    when: row => row.Granel < 0 && row.Tipo === 'RESULTADO',
                    style: {
                        backgroundColor: 'rgba(242, 38, 19, 0.9)',
                        color: 'white',
                        // '&:hover': {
                        //     cursor: 'pointer',
                        // },
                    },
                },
            ],

        },
        {
            name: 'GARRAFA',
            right: true,
            selector: row => row.Garrafa,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.Garrafa)
            },
            width: "75px",
            grow: 1,
        },

        {
            name: 'CAPT GARR',
            right: true,
            selector: row => row.CapGarr,
            width: "85px",
            grow: 1,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.CapGarr)
            }
        },

        {
            name: 'CAPT GR',
            right: true,
            width: "75px",
            grow: 1,
            selector: row => row.CaptGr,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.CaptGr)
            }
        },

        {
            name: 'GDES REDES',
            right: true,
            selector: row => row.GdesRedes,
            width: "90px",
            grow: 1,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.GdesRedes)
            }
        },
        {
            name: 'COLETORES',
            right: true,
            selector: row => row.Coletores,
            width: "85px",
            grow: 1,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.Coletores)
            }
        },

        {
            name: 'RECUP IMP',
            right: true,
            width: "75px",
            grow: 1,
            selector: row => row.RecupImp,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.RecupImp)
            }
        },

        {
            name: 'RECUP RESID',
            right: true,
            selector: row => row.RecupResid,
            width: "95px",
            grow: 1,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.RecupResid)
            },

        },
        {
            id: 'PercentualSujeira',
            name: 'SUJEIRA',
            width: "70px",
            right: true,
            selector: row => row.PercentualSujeira,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.PercentualSujeira) + '%'
            },
        },


        {
            name: 'SABÃO',
            right: true,
            selector: row => row.Sabao,
            width: "60px",
            grow: 1,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.Sabao)
            }
        },
        {
            name: 'BIODIESEL',
            right: true,
            selector: row => row.BioDiesel,
            width: "80px",
            grow: 1,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.BioDiesel)
            }
        },
        // {
        //     name: 'SAÍDA PLANEJADA',
        //     right: true,
        //     selector: row => row.SaidaPlanejada,
        //     width: "120px",
        //     grow: 1,
        //     format: (row, index) => {
        //         return Intl.NumberFormat('pt-BR').format(row.SaidaPlanejada)
        //     }
        // },
        {
            name: 'IMPUREZA',
            right: true,
            width: "75px",
            grow: 1,
            selector: row => row.Impureza,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.Impureza)
            }
        },
        {
            id: 'ResiduoPesagem',
            name: 'RESID PESAGEM',
            right: true,
            selector: row => row.ResiduoPesagem,
            width: "105px",
            grow: 1,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.ResiduoPesagem)
            }
        },
        {
            name: 'TANQUE KG',
            right: true,
            selector: row => row.TanqueKg,
            width: "80px",
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.TanqueKg)
            }

        },
        {
            name: 'TANQUE LTS',
            right: true,
            selector: row => row.TanqueLTS,
            width: "80px",
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.TanqueLTS)
            }
        },
        // {
        //     name: 'Estoque',
        //     selector: row => row.Estoque,
        // },
        // {
        //     id: 'Saida',
        //     name: 'Saída',
        //     selector: row => row.Saida,
        // }

    ];
    const columns2 = [
        {
            name: 'TIPO',
            selector: row => row.Tipo,
            id: 'Tipo',
            width: "100px",

        },
        {
            name: 'DATA',
            selector: row => row.Data,
            width: "60px",
        },
        {
            name: 'GRANEL',
            right: true,
            grow: 1,
            width: "70px",
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.Granel)
            },
            selector: row => row.Granel,
            conditionalCellStyles: [
                {
                    when: row => row.Granel < 0 && row.Tipo === 'RESULTADO',
                    style: {
                        backgroundColor: 'rgba(242, 38, 19, 0.9)',
                        color: 'white',
                        // '&:hover': {
                        //     cursor: 'pointer',
                        // },
                    },
                },
            ]
        },
        {
            name: 'GARRAFA',
            right: true,
            width: "80px",
            selector: row => row.Garrafa,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.Garrafa)
            },
        },

        {
            name: 'CAPT GARR',
            right: true,
            width: "90px",
            selector: row => row.CapGarr,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.CapGarr)
            },
        },

        {
            name: 'CAPT GR',
            right: true,
            width: "80px",
            selector: row => row.CaptGr,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.CaptGr)
            },
        },

        {
            name: 'GDES REDES',
            right: true,
            width: "90px",
            selector: row => row.GdesRedes,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.GdesRedes)
            },
        },

        // {
        //     name: 'Recup Imp',
        //     right: true,
        //     selector: row => row.RecupImp,
        // },

        // {
        //     name: 'Recup Resid',
        //     right: true,
        //     selector: row => row.RecupResid,
        //     format: (row, index) => {
        //         return Intl.NumberFormat('pt-BR').format(row.RecupResid)
        //     },
        // },

        {
            name: 'COLETORES',
            right: true,
            width: "90px",
            selector: row => row.Coletores,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.Coletores)
            },
        },
        {
            name: 'SABÃO',
            width: "80px",
            right: true,
            selector: row => row.Sabao,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.Sabao)
            },
        },
        {
            id: 'SaidaPlanejada',
            name: 'SAÍDA PLANEJADA',
            right: true,
            width: "130px",
            selector: row => row.SaidaPlanejada,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.SaidaPlanejada)
            },
        },
        {
            id: 'ImpurezaPlanejada',
            name: 'IMPUREZA PLANEJADA',
            width: "150px",
            right: true,
            selector: row => row.ImpurezaPlanejada,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.ImpurezaPlanejada)
            },
        },
        {
            id: 'PercentualImpurezaMes',
            name: '% Sujeira',
            width: "80px",
            right: true,
            selector: row => row.PercentualImpurezaMes,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.PercentualImpurezaMes) + '%'
            },
        },



        // {
        //     name: 'TANQUE KG',
        //     right: true,
        //     selector: row => row.TanqueKg,
        // },
        // {
        //     name: 'TANQUE LTS',
        //     right: true,
        //     selector: row => row.TanqueLTS,
        // },
        // {
        //     name: 'Estoque',
        //     selector: row => row.Estoque,
        // },
        // {
        //     id: 'Saida',
        //     name: 'Saída',
        //     selector: row => row.Saida,
        // }

    ];



    const columnsResumo = [
        {
            name: 'TIPO',
            selector: row => row.Tipo,
            id: 'Tipo',

        },
        {
            name: 'REAL',
            selector: row => row.Real,
            id: 'Real',
            right: true,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.Real)
            }
        },
        {
            name: '%',
            selector: row => row.PercentualReal,
            id: 'PercentualReal',
            right: true,
            format: (row, index) => {
                if (row.Tipo === 'SOMA') {
                    return ''
                } else {
                    return Intl.NumberFormat('pt-BR').format(row.PercentualReal) + '%'
                }
            }
        },
        {
            name: 'PLANEJADO',
            selector: row => row.Planejado,
            id: 'Planejado',
            right: true,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.Planejado)
            }
        },
        {
            name: '%',
            selector: row => row.PercentualPlanejado,
            id: 'PercentualPlanejado',
            right: true,
            format: (row, index) => {
                if (row.Tipo === 'SOMA') {
                    return ''
                } else {
                    return Intl.NumberFormat('pt-BR').format(row.PercentualPlanejado) + '%'
                }
            }
        },
        {
            name: 'RESULTADO',
            selector: row => row.Resultado,
            id: 'Resultado',
            right: true,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.Resultado)
            }
        },
    ];

    const customStyles = {

        subHeader: {
            style: {
                paddingLeft: '0px', // override the cell padding for data cells
                paddingRight: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '0px',
                paddingRight: '0px',
            },
            draggingStyle: {
                cursor: 'move',
            },
        },
        head: {
            style: {
                paddingLeft: '0px', // override the cell padding for data cells
                paddingRight: '0px',
            },
        },
        header: {
            minHeight: '20px',
            zIndex: 0,
            paddingLeft: '0px', // override the cell padding for data cells
            paddingRight: '0px',
        },
        headRow: {
            style: {
                minHeight: '20px',
                zIndex: 0,
                paddingLeft: '0px', // override the cell padding for data cells
                paddingRight: '0px',
            }
        },
        cells: {
            style: {
                paddingLeft: '0px', // override the cell padding for data cells
                paddingRight: '0px',
            },
        },
        rows: {
            style: {
                minHeight: '20px', // override the row height
                paddingLeft: '0px', // override the cell padding for data cells
                paddingRight: '0px',
            },
        },
    }


    return (

        <Container>
            <ToastContainer />
            <BlockScreenLoading loading display={blockScreen} />

            <Grid container className={styles.filtro}>
                <Grid item xs={3} >
                    <label> Mês </label>
                    <ReactSelect
                        name='mes'
                        //defaultValue={selectedOption}
                        defaultValue={{ label: "Março", value: "3" }}
                        onChange={setSelectedOption}

                        //onChange={value => handleChangeMes(value)}
                        options={meses}



                    />

                </Grid>
                <Grid item xs={1} >

                </Grid>

                <Grid item xs={2} >
                    <label> Ano </label>
                    <ReactSelect
                        name='ano'
                        defaultValue={{ label: "2023", value: "2023" }}

                        onChange={setselectedAno}
                        options={ano}
                    />
                </Grid>
                <Grid item xs={1} >

                </Grid>
                <Grid item xs={3}>
                    <Button
                        onClick={() => teste()}
                        disabled={status}
                    >Atualizar Indicador</Button>
                </Grid>


            </Grid >




            <div className={styles.container_grid}>
                <div>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}>

                        <p>Volume mês anterior: <b> {Intl.NumberFormat('pt-BR').format(VolumeInicioMes)}</b></p>
                        <IconButton aria-label="delete" onClick={edit_volume_tanque}>
                            <ModeEditOutlineIcon />
                        </IconButton>
                    </Stack>
                </div>


                <listagemcoletas className={styles.coletas}>

                    <Box style={{ padding: '0px' }}  >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} style={{ padding: '0px' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Dinâmico" {...a11yProps(0)} />
                                <Tab label="Planejado" {...a11yProps(1)} />
                                <Tab label="Resumo" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0} style={{ padding: '0px' }}>
                            <DataTable
                                // title="Indicador Diário Janeiro/2023"
                                columns={columns}
                                data={Indicador}
                                // pagination
                                //paginationPerPage={11}
                                // dense
                                //fixedHeader
                                progressPending={pending}
                                progressComponent={<CustomLoader />}
                                customStyles={customStyles}
                                highlightOnHover
                            //pointerOnHover

                            //conditionalRowStyles={conditionalRowStyles}

                            />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <DataTable
                                // title="Indicador Diário Janeiro/2023"
                                columns={columns2}
                                data={Planejado}
                                // pagination
                                // paginationPerPage={91}
                                //dense
                                //fixedHeader
                                progressPending={pending}
                                progressComponent={<CustomLoader />}
                                customStyles={customStyles}
                                highlightOnHover
                            // conditionalRowStyles={conditionalRowStyles}
                            // footer={columns}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <DataTable

                                columns={columnsResumo}
                                data={Resumo}
                                // pagination
                                // paginationPerPage={91}
                                dense
                                //fixedHeader
                                progressPending={pending}
                                progressComponent={<CustomLoader />}
                                highlightOnHover
                            // conditionalRowStyles={conditionalRowStyles}
                            // footer={columns}
                            />
                        </TabPanel>
                    </Box>


                </listagemcoletas>


            </div>

            <div>

                <Modal
                    open={openModalTanque}
                    onClose={handleCloseModalTanque}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={styleModalTanque}>
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            Edição do Volume do Tanque
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <Box sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    id="outlined-controlled"
                                    label="VOLUME TANQUE"
                                    value={novoVolumeInicioMes}
                                    size="small"
                                    type='number'
                                    onChange={(event) => {
                                        setNovoVolumeInicioMes(event.target.value);
                                    }}
                                />


                            </Box>
                            <Typography sx={{ mt: 2 }}>

                                <Stack direction="row" spacing={2}>
                                    <Button color="primary" onClick={salvar_volume_tanque} >Salvar</Button>

                                    <Button color="secondary" onClick={handleCloseModalTanque} href="#contained-buttons">
                                        Cancelar
                                    </Button>
                                </Stack>
                            </Typography>
                        </Typography>
                    </Box>
                </Modal>
            </div>


            <div>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {/* <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Edição do planejamento
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 10 }}>
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </Typography>
                    </Box> */}
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Edição do planejamento de {dataPlanejado}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <Box sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    id="outlined-controlled"
                                    label="GRANEL"
                                    value={granelPlanejado}
                                    onChange={(event) => {
                                        setGranelPlanejado(event.target.value);
                                    }}
                                />
                                <TextField
                                    id="outlined-uncontrolled"
                                    label="GARRAFA"
                                    value={garrafaPlanejado}
                                    onChange={(event) => {
                                        setGarrafaPlanejado(event.target.value);
                                    }}
                                />
                                <TextField
                                    id="outlined-uncontrolled"
                                    label="CAPT GARRAFA"
                                    value={captGarrafaPlanejado}
                                    onChange={(event) => {
                                        setCaptGarrafaPlanejado(event.target.value);
                                    }}
                                />
                                <TextField
                                    id="outlined-uncontrolled"
                                    label="CAPT GRANEL"
                                    value={captGranelPlanejado}
                                    onChange={(event) => {
                                        setCaptGranelPlanejado(event.target.value);
                                    }}
                                />
                                <TextField
                                    id="outlined-uncontrolled"
                                    label="GRANDES REDES"
                                    value={grandesRedesPlanejada}
                                    onChange={(event) => {
                                        setGrandesRedesPlanejado(event.target.value);
                                    }}
                                />
                                <TextField
                                    id="outlined-uncontrolled"
                                    label="COLETORES"
                                    value={coletoresPlanejado}
                                    onChange={(event) => {
                                        setColetoresPlanejado(event.target.value);
                                    }}
                                />
                                <TextField
                                    id="outlined-uncontrolled"
                                    label="SABÃO"
                                    value={sabaoPlanejado}
                                    onChange={(event) => {
                                        setSabaoPlanejado(event.target.value);
                                    }}
                                />
                                <TextField
                                    id="outlined-uncontrolled"
                                    label="SAÍDA PLANEJADA"
                                    value={saidaPlanejada}
                                    onChange={(event) => {
                                        setSaidaPlanejada(event.target.value);
                                    }}
                                />

                            </Box>
                            <Typography sx={{ mt: 2 }}>

                                <Stack direction="row" spacing={2}>
                                    <Button color="primary" onClick={EditarPlanejamento} >Salvar</Button>

                                    <Button color="secondary" onClick={handleClose} href="#contained-buttons">
                                        Cancelar
                                    </Button>
                                </Stack>
                            </Typography>
                        </Typography>
                    </Box>
                </Modal>
            </div>


        </Container >

    )
}

export default Indicador
