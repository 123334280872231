import styles from './OptionMenu.module.css'
import { Link } from 'react-router-dom'
import { useState } from 'react'

export function OptionMenu(propsNormalMenu) {
  return (
    <li className={styles.optionMenu}>
      <Link
        to={propsNormalMenu?.link}
        onClick={propsNormalMenu?.onClick}
        target={!propsNormalMenu?.target ? '_self' : propsNormalMenu?.target}
      >
        <i className={propsNormalMenu?.icon}></i>
        {propsNormalMenu?.title}
      </Link>
    </li>
  )
}

export function OptionMenuDropDown(propsDropdown) {

  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={styles.dropdown} onClick={() => setIsOpen(!isOpen)}>
      <li className={styles.optionMenu}>
        <div className={styles.labelIcons}>
          <span>
            <i className={propsDropdown?.iconDropdown}></i>
            {propsDropdown?.labelDropdown}
          </span>
          {isOpen ? (
            <i className="fas fa-times"></i>
          ) : (
            <i className="fas fa-caret-down"></i>
          )}
        </div>
      </li>
      {/*  */}
      <div className={isOpen ? styles.dropdownChildren : styles.hidden}>
        {propsDropdown?.options?.map((values, index) => (
          <li className={styles.optionMenu} key={index}>
            <Link
              to={values?.link}
              onClick={propsDropdown?.onClick}
              target={!values?.target ? '_self' : values?.target}
            >
              <span>{''}</span>
              <i className={values?.icon}></i>
              {values?.title}
            </Link>
          </li>
        ))}
      </div>
    </div>
  )
}
