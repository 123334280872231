import { useContext, useEffect, useState } from 'react'
import Container from '../../../components/components-ui/Container/Container'
import { Grid, Col } from '../../../components/Grid'
import { Formik, Form, Field } from 'formik'
import Button from '../../../components/components-ui/Button/Button'
import styles from './ordemcarregamento.module.css'
import DataTable from 'react-data-table-component';


const columns = [
  {
    name: 'CEP',
    selector: row => row.cep,
  },
  {
    name: 'Nº Coleta',
    selector: row => row.title,
  },
  {
    name: 'Nome Local',
    selector: row => row.nome_local,
  },
];

const data = [
  {
    id: 1,
    cep: '02116-001',
    title: '123',
    nome_local: 'Resaturante 01',
  },
  {
    id: 2,
    cep: '02117-001',
    title: '456',
    nome_local: 'Resaturante 02',
  },
]

function CriacaoRotaLogistica() {
  const [status, setStatus] = useState([])
  const [estabelecimento, setEstabelecimento] = useState([])
  return (
    <Container>
      <h3>Ordem de Carregamento</h3>

      <div className={styles.container_grid}>
        <filtro className={styles.filtros}>
          <Formik
            initialValues={{
              codigoPonto: ''

              //   ,
              // razaoSocial: dataLocalSearch?.razaoSocial
              //   ? dataLocalSearch?.razaoSocial
              //   : '',
              // estabelecimento: dataLocalSearch?.estabelecimento
              //   ? dataLocalSearch?.estabelecimento
              //   : '',
              // status: dataLocalSearch?.status ? dataLocalSearch?.status : '',
              // grupo: dataLocalSearch?.grupo ? dataLocalSearch?.grupo : '',
            }}
            // ;validationSchema={ValidationSchema}
            onSubmit={(values) => ''}
          >
            {({ errors, touched }) => (
              <Form>
                <section className={styles.wrap}>
                  <div className={styles.filter_header}>
                    <strong>Filtros</strong>
                    {/* <Button title="Procurar" type="submit" /> */}
                  </div>
                  <Grid>
                    <Col md={2}>
                      <label>Nº Coleta</label>
                      <Field type="number" name="codigoPonto" />
                      {errors.IdProspeccao && touched.IdProspeccao ? (
                        <span className={styles.alertError}>
                          {errors.IdProspeccao}
                        </span>
                      ) : null}
                    </Col>

                    <Col md={4}>
                      <label>Razão Social</label>
                      <Field type="text" name="razaoSocial" />
                      {errors.razaoSocial && touched.razaoSocial ? (
                        <span className={styles.alertError}>
                          {errors.razaoSocial}
                        </span>
                      ) : null}
                    </Col>

                    <Col md={2}>
                      <label>Status</label>
                      <Field component="select" name="status">
                        <option value="">Selecione</option>
                        {status.map((values) => (
                          <option
                            key={values?.id_status}
                            value={values?.id_status}
                          >
                            {values?.status}
                          </option>
                        ))}
                      </Field>
                      {errors.status && touched.status ? (
                        <span className={styles.alertError}>{errors.status}</span>
                      ) : null}
                    </Col>
                    <Col md={2}>
                      <label>Estabelecimento</label>
                      <Field component="select" name="estabelecimento">
                        <option value="">Selecione</option>
                        {estabelecimento.map((values) => (
                          <option
                            key={values?.id_estabelecimento}
                            value={values?.id_estabelecimento}
                          >
                            {values?.estabelecimento}
                          </option>
                        ))}
                      </Field>
                      {errors.estabelecimento && touched.estabelecimento ? (
                        <span className={styles.alertError}>
                          {errors.estabelecimento}
                        </span>
                      ) : null}
                    </Col>
                    <Col md={2}>
                      <label>Grupo</label>
                      <Field component="select" name="grupo">
                        <option value="">Selecione</option>
                        {/* {grupo.map((values) => (
                        <option key={values?.id_grupo} value={values?.id_grupo}>
                          {values?.descricao_grupo}
                        </option>
                      ))} */}
                      </Field>
                      {/* {errors.grupo && touched.grupo ? (
                      <span className={styles.alertError}>{errors.grupo}</span>
                    ) : null} */}
                    </Col>
                  </Grid>
                </section>
              </Form>
            )}
          </Formik>
        </filtro>

        <listagemcoletas className={styles.coletas}>
          <DataTable
            title=" "
            columns={columns}
            data={data}
            selectableRows
          />
        </listagemcoletas>

        <Grid >
          <Col md={3}>
            <Button title="Criar Rota"></Button>
          </Col>
          <Col md={3}>
            <Button title="Resumo OC"></Button>
          </Col>
          <Col md={3}>

            <Button title="Selecionar Coletas"></Button>
          </Col>

        </Grid>
      </div>


    </Container >
  )
}

export default CriacaoRotaLogistica
