import React, { useContext } from 'react'
import styles from './Header.module.css'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../Provider/AuthContext'

export default function Header({ onClick, color }) {
  const { nome_usuario, novaVersao, appNome, appLogo, idTipoUsuario } =
    useContext(AuthContext)
  return (
    <header
      className={styles.header}
      style={
        color === '1'
          ? { backgroundColor: '#dbddd0' }
          : { backgroundColor: '#fff' }
      }
    >
      <div className={styles.logo}>
        {idTipoUsuario !== 5 ? (
          <>
            <input
              type="checkbox"
              className={styles.menu}
              id="check"
              onClick={onClick}
            />
            <label htmlFor="check" className={styles.iconMenu}>
              <i className="fas fa-bars"></i>
            </label>
          </>
        ) : null}

        <Link to="#">
          <img src={appLogo} alt="Logotipo" />
          <strong>
            APP {appNome} - V. {novaVersao}
          </strong>
        </Link>
      </div>
      <div className={styles.profile}>
        <span>
          Bem Vindo
          {/* to={`${validationRoute()}/atualizar-usuario`} */}
          <Link to="#">
            <strong title="Atualizar Usuario">{nome_usuario}</strong>
            {/* <i className="fas fa-pencil-alt"></i> */}
          </Link>
        </span>
        <img src="/assets/user.png" alt="LogoProfile" />
      </div>
    </header>
  )
}
