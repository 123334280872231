import { Link } from 'react-router-dom'

import styles from './OptionDropdown.module.css'
export default function OptionDropdown(values, index) {
  // const [isOpen, setIsOpen] = useState(false)
  // { isOpen ? styles.dropdown_content : styles.hidden onClick={() => setIsOpen(!isOpen)} }
  return (

    <div key={index} className={styles.dropdown}>
      <div className={styles.option}>
        <i style={{ marginRight: '5px' }} className={values?.icon}></i>
        {values?.title}
        <i style={{ marginLeft: '5px' }} className="fas fa-sort-down"></i>
      </div>
      <div className={styles.dropdown_content}>
        {values?.option.map((values, index) => (
          <div key={index} className={styles.dropdown_link}>
            <Link to={values?.link}>{values?.title}</Link>
          </div>
        ))}


        {values?.suboption
          ? values.suboption.map((values, index) => (
            <div key={index} className={styles.sub_dropdown}>
              <span>{values?.subtitle}</span>
              <i className="fas fa-chevron-right"></i>

              <div className={styles.sub_dropdown_content}>
                {values?.suboption.map((values, index) => (
                  <div key={index} className={styles.dropdown_link}>
                    <Link to={values?.link}>{values?.title}</Link>
                  </div>
                ))}
              </div>
            </div>
          ))
          : null}
      </div>
    </div>
  )
}
