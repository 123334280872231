import styles from './PainelDeLigacao.module.css'
import Container from '../../../../components/components-ui/Container/Container'
import { Formik, Form, Field } from 'formik'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Modal from '../../../../components/Modal'
import DetalhesDoLocal from './DetalhesDoLocal'
import { Grid, Col } from '../../../../components/Grid'
import { InputMasked } from '../../../../components/components-ui/InputMask/InputMask'
import { Table } from '../../../../components/Table'
import DataTable from 'react-data-table-component';
import api from '../../../../services/Api'
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';


function PainelDeLigacao() {
  const [painelLigacao, setPainelLigacao] = useState([])
  const [showModal, setShowModal] = useState(false)


  useEffect(() => {


    GetListPainelLigacao()


  }, [])

  const handleButtonClick = () => {

    console.log('clicked');
  };


  const columns = [
    {

      cell: () =>
        <IconButton onClick={handleButtonClick}><SearchIcon sx={{ fontSize: 15, color: '#f44336f7' }} /></IconButton >,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "50px",
    },
    {
      name: 'CEP',
      selector: row => row.CEP,
      id: 'CEP',
      width: "100px",
      grow: 1,
    },
    {
      name: 'Cód. Local',
      selector: row => row.IDLocal,
      id: 'IDLocal',
      width: "90px",
      grow: 1,

    },
    {
      name: 'Nome Local',
      selector: row => row.NomeLocal,
      id: 'NomeLocal',
      width: "300px",
      grow: 1,
    },
    {
      name: 'Média',
      selector: row => row.MediaOleoColetado,
      id: 'MediaOleoColetado',
      width: "70px",
      grow: 1,
      right: true,
      format: (row, index) => {
        return Intl.NumberFormat('pt-BR').format(row.MediaOleoColetado)
      },

    },
    {
      name: 'Diferença',
      selector: row => row.DiferencaKgMeta,
      id: 'DiferencaKgMeta',
      width: "95px",
      right: true,
      grow: 1,
      format: (row, index) => {
        return Intl.NumberFormat('pt-BR').format(row.DiferencaKgMeta)
      },
    },
    {
      name: 'Prev. Kg Hj',
      selector: row => row.PrevistoKgHoje,
      id: 'PrevistoKgHoje',
      width: "100px",
      right: true,
      grow: 1,
      format: (row, index) => {
        return Intl.NumberFormat('pt-BR').format(row.PrevistoKgHoje)
      },
    },
    {
      name: 'Méd. Imp.',
      selector: row => row.PercentualMediaImpureza,
      id: 'PercentualMediaImpureza',
      right: true,
      format: (row, index) => {
        return Intl.NumberFormat('pt-BR').format(row.PercentualMediaImpureza)
      },
      width: "95px",
      grow: 1,
    },
    {
      name: 'Dt Ult. Ctt.',
      selector: row => row.DataUltimoContato,
      id: 'DataUltimoContato',
      width: "150px",
      grow: 1,
    },
    {
      name: 'Dt Prev. Px. Lig',
      selector: row => row.DataPrevistaLigacao,
      id: 'DataPrevistaLigacao',
      width: "150px",
      grow: 1,
    },
    {
      name: 'Vol. Col.',
      selector: row => row.VolumeColetado,
      id: 'VolumeColetado',
      width: "100px",
      grow: 1,
      right: true,
      format: (row, index) => {
        return Intl.NumberFormat('pt-BR').format(row.VolumeColetado)
      },
    },
    {
      name: 'Nome Atendente',
      selector: row => row.NomeAtendente,
      id: 'NomeAtendente',
      width: "150px",
      grow: 1,
    },
    {
      name: 'Campanha',
      selector: row => row.Campanha,
      id: 'Campanha',
      width: "150px",
      grow: 1,
    },
    {
      name: 'Estabelecimento',
      selector: row => row.Estabelecimento,
      id: 'Estabelecimento',
      width: "150px",
      grow: 1,
    },
    {
      name: 'Grupo',
      selector: row => row.DescricaoGrupo,
      id: 'DescricaoGrupo',
      width: "150px",
      grow: 1,
    },
    {
      name: 'Email',
      selector: row => row.Email,
      id: 'Email',
      width: "200px",
      grow: 1,
    },

  ]


  async function GetListPainelLigacao() {
    const response = await api.get(`/painelligacao/index`)
    setPainelLigacao(response.data)

  }




  return (
    <Container>
      <h3> Painel de Ligação </h3>
      <div className={styles.content}>
        <Formik
          initialValues={{
            codigoPonto: '',
            razaoSocial: '',
            estabelecimento: '',
            status: 3,
            grupo: '',
          }}
          //validationSchema={ValidationSchema}
          onSubmit={(values) => console.log(values)}
        >
          {({ errors, touched }) => (
            <Form>
              <section className={styles.wrap}>
                <div className={styles.filter_header}>
                  <strong>Filtros</strong>
                </div>
                <Grid>
                  <Col md={2}>
                    <label>Faixa CEP de</label>
                    {/* <Field type="number" name="faixaCepDe" /> */}
                    <Field name="faixaCepDe">
                      {({ field }) => <InputMasked {...field} mask="99999-999" />}
                    </Field>
                    {errors.faixaCepDe && touched.faixaCepDe ? (
                      <span className={styles.alertError}>
                        {errors.faixaCepDe}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Faixa CEP Até</label>
                    {/* <Field type="number" name="faixaCepAte" /> */}
                    <Field name="faixaCepAte">
                      {({ field }) => <InputMasked {...field} mask="99999-999" />}
                    </Field>
                    {errors.faixaCepAte && touched.faixaCepAte ? (
                      <span className={styles.alertError}>
                        {errors.faixaCepAte}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Cod. Local</label>
                    <Field type="text" name="codLocal" />
                    {errors.codLocal && touched.codLocal ? (
                      <span className={styles.alertError}>
                        {errors.codLocal}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={3}>
                    <label>Local</label>
                    <Field type="text" name="local" />
                    {errors.local && touched.local ? (
                      <span className={styles.alertError}>{errors.local}</span>
                    ) : null}
                  </Col>
                  <Col md={3}>
                    <label>Endereço Local</label>
                    <Field type="text" name="enderecoLocal" />
                    {errors.enderecoLocal && touched.enderecoLocal ? (
                      <span className={styles.alertError}>
                        {errors.enderecoLocal}
                      </span>
                    ) : null}
                  </Col>
                </Grid>
                <Grid>
                  <Col md={2}>
                    <label>Retorno</label>
                    <Field component="select" name="retorno">
                      <option value="">Selecione</option>
                    </Field>
                    {errors.retorno && touched.retorno ? (
                      <span className={styles.alertError}>
                        {errors.retorno}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={3}>
                    <label>Estabelecimento</label>
                    <Field component="select" name="estabelecimento">
                      <option value="">Selecione</option>
                    </Field>
                    {errors.estabelecimento && touched.estabelecimento ? (
                      <span className={styles.alertError}>
                        {errors.estabelecimento}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Grupo</label>
                    <Field component="select" name="grupo">
                      <option value="">Selecione</option>
                    </Field>
                    {errors.grupo && touched.grupo ? (
                      <span className={styles.alertError}>{errors.grupo}</span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Grupo</label>
                    <Field component="select" name="status">
                      <option value="">Selecione</option>
                    </Field>
                    {errors.status && touched.status ? (
                      <span className={styles.alertError}>{errors.status}</span>
                    ) : null}
                  </Col>
                  <Col md={3}>
                    <label>Média De</label>
                    <Field type="number" name="mediaDe" />
                    {errors.mediaDe && touched.mediaDe ? (
                      <span className={styles.alertError}>
                        {errors.mediaDe}
                      </span>
                    ) : null}
                  </Col>
                </Grid>
                <Grid>
                  <Col md={3}>
                    <label>Média Até</label>
                    <Field type="number" name="mediaDe" />
                    {errors.mediaDe && touched.mediaDe ? (
                      <span className={styles.alertError}>
                        {errors.mediaDe}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Dt.Px. Ligação De</label>
                    <Field type="date" name="dtPxLigacaoDe" />
                    {errors.dtPxLigacaoDe && touched.dtPxLigacaoDe ? (
                      <span className={styles.alertError}>
                        {errors.dtPxLigacaoDe}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Dt.Prx. Ligação Até</label>
                    <Field type="date" name="dtPrxLigacaoAte" />
                    {errors.dtPrxLigacaoAte && touched.dtPrxLigacaoAte ? (
                      <span className={styles.alertError}>
                        {errors.dtPrxLigacaoAte}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Dt. Ul. Coleta De</label>
                    <Field type="date" name="dtUlColetaDe" />
                    {errors.dtUlColetaDe && touched.dtUlColetaDe ? (
                      <span className={styles.alertError}>
                        {errors.dtUlColetaDe}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={3}>
                    <label>Dt. Ul. Coleta Até</label>
                    <Field type="date" name="dtUlColetaAte" />
                    {errors.dtUlColetaAte && touched.dtUlColetaAte ? (
                      <span className={styles.alertError}>
                        {errors.dtUlColetaAte}
                      </span>
                    ) : null}
                  </Col>
                </Grid>
                <Grid>
                  <Col md={2}>
                    <label>Dt. Ul. Ligação De</label>
                    <Field type="date" name="dtUlLigacaoDe" />
                    {errors.dtUlLigacaoDe && touched.dtUlLigacaoDe ? (
                      <span className={styles.alertError}>
                        {errors.dtUlLigacaoDe}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Dt. Ul. Ligação Até</label>
                    <Field type="date" name="dtUlLigacaoAte" />
                    {errors.dtUlLigacaoAte && touched.dtUlLigacaoAte ? (
                      <span className={styles.alertError}>
                        {errors.dtUlLigacaoAte}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={3}>
                    <label>Status Coleta</label>
                    <Field component="select" name="statusColeta">
                      <option value="">Selecione</option>
                    </Field>
                    {errors.statusColeta && touched.statusColeta ? (
                      <span className={styles.alertError}>
                        {errors.statusColeta}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={3}>
                    <label>Tipo Contato</label>
                    <Field component="select" name="tipoContato">
                      <option value="">Selecione</option>
                    </Field>
                    {errors.tipoContato && touched.tipoContato ? (
                      <span className={styles.alertError}>
                        {errors.tipoContato}
                      </span>
                    ) : null}
                  </Col>
                  <Col md={2}>
                    <label>Campanha</label>
                    <Field component="select" name="campanha">
                      <option value="">Selecione</option>
                    </Field>
                    {errors.campanha && touched.campanha ? (
                      <span className={styles.alertError}>
                        {errors.campanha}
                      </span>
                    ) : null}
                  </Col>
                </Grid>
              </section>
            </Form>
          )}
        </Formik>

        <DataTable
          // title="Indicador Diário Janeiro/2023"
          columns={columns}
          data={painelLigacao}
          pagination
          paginationPerPage={20}
          dense
        // fixedHeader
        // progressPending={pending}
        // progressComponent={<CustomLoader />}
        //customStyles={customStyles}
        //highlightOnHover
        //pointerOnHover

        //conditionalRowStyles={conditionalRowStyles}

        />
        {/* <Table edit columns={columns}>
          <tr>
            <td>
              <Link to="#" onClick={() => setShowModal(true)}>
                <i className="fas fa-pencil-alt"></i>
              </Link>
            </td>
          </tr>
        </Table> */}
      </div>
      <Modal
        titleModal="Detalhes Local"
        display={showModal}
        onClick={() => setShowModal(false)}
      >
        <DetalhesDoLocal />
      </Modal>
    </Container>
  )
}

export default PainelDeLigacao
