import styles from './ListaRoteiros.module.css'
import Container from '../../../components/components-ui/Container/Container'
import Loading from '../../../components/components-ui/Loading/Loading'
import { Table } from '../../../components/Table'
import UINumber from '../../../components/components-ui/UINumber/UINumber'
import api from '../../../services/Api'
import { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons'




export default function ListaRoteiros() {
  const history = useHistory()
  const { id } = useParams()
  const location = useLocation();
  const [detalheRoteiro, setDetalheRoteiro] = useState([])
  const [roteirofiltrado, setRoteirofiltrado] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  const [termoPesquisa, setTermoPesquisa] = useState('');


  const DataFreguesia = location.DataFreguesia;
  const IdOc = location.IdOc;



  //const [colorRoteiro, setColorRoteiro] = useState()

  useEffect(() => {
    async function getDetalhesRoteiros() {
      setShowLoading(true)
      const response = await api.post(`/motorista/roteirodetalhe/${id}`)
      //console.log(response.data)



      setDetalheRoteiro(response.data)

      setRoteirofiltrado(response.data)

      setShowLoading(false)
    }
    getDetalhesRoteiros()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])




  const handlePesquisaChange = (event) => {
    const novoTermoPesquisa = event.target.value;
    setTermoPesquisa(novoTermoPesquisa);

    // Cancela o timeout anterior, se houver
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Configura um novo timeout para 3 segundos
    const novoTimeoutId = setTimeout(() => {
      realizarPesquisa(novoTermoPesquisa);
    }, 1000);

    // Armazena o ID do timeout atual
    setTimeoutId(novoTimeoutId);
  };

  const realizarPesquisa = (termo) => {
    // Aqui você pode realizar a lógica de pesquisa, por exemplo, chamar uma API, atualizar o estado, etc.
    // console.log('Pesquisando por:', termo);
    setShowLoading(true)

    console.log(termo)

    var local_filtrado = detalheRoteiro.filter((f) => f.nome_local && f.nome_local.toLowerCase().includes(termo.toLowerCase()));




    if (termo.length == 0) {
      setRoteirofiltrado(detalheRoteiro)
    } else {
      setRoteirofiltrado(local_filtrado)
    }
    console.log(termo.length)


    setShowLoading(false)
  };

  // Estado para armazenar o ID do timeout atual
  const [timeoutId, setTimeoutId] = useState(null);

  function ColetaRecado(props) {
    const exibirHTML = props.recado ? (
      <div style={{ color: '#d81e0c' }}>
        <strong style={{ color: '#d81e0c' }}>Coleta Recado: </strong> {props.IDColeta}
      </div>
    ) : (
      <div>
      </div>
    );

    return (
      <div>
        {exibirHTML}
      </div>
    );
  }

  function ExibeDataAgendamento(props) {
    const exibirHTML = props.DataAgendamento ? (
      <div >
        <strong>Data Agendamento: </strong> {props.DataAgendamento}
      </div>
    ) : (
      <div>
      </div>
    );

    return (
      <div>
        {exibirHTML}
      </div>
    );
  }

  function ObservacaoColetaRecado(props) {
    const exibirHTML = props.recado ? (
      <div style={{ color: '#d81e0c' }}>
        <strong style={{ color: '#d81e0c' }}>Observação: </strong> {props.Observacoes}
      </div>
    ) : (
      <div>
      </div>
    );

    return (
      <div>
        {exibirHTML}
      </div>
    );
  }





  return (
    <Container>
      <div className={styles.content}>
        <div className={styles.btn_footer}>
          <Link
            to="#"
            onClick={() => history.push('/motorista')}
            className={styles.backButton}
          >
            <i className="fas fa-arrow-left"></i> Voltar
          </Link>
        </div>
        <h3>Listagem da Freguesia {DataFreguesia} - ({IdOc})</h3>

        <div className={styles.pesquisa}>
          <ul>
            <li>
              <p>Pesquisar por Marca</p>
              <input
                style={{ width: '100%' }}
                type="text"
                placeholder="Pesquisar Marca"
                value={termoPesquisa}
                onChange={handlePesquisaChange}
                name="handlePesquisar"
                id="pesquisar_local"
              />
            </li>
          </ul>
        </div>

        {/* <Table columns={columns}>
          <tr>
            <td><div className={styles.btn_footer}>
              <Link
                to="#"
                onClick={() => history.push('/#')}
                className={styles.backButton}
              >
                <i className="fas fa-arrow-new"></i> +
              </Link>
            </div></td>
            <td>{detalheRoteiro[0]?.data_oc}</td>
            <td>{detalheRoteiro[0]?.km_inicial_motorista}</td>
            <td>{detalheRoteiro[0]?.placa_veiculo}</td>
            <td>{detalheRoteiro[0]?.motorista}</td>
          </tr>
        </Table> */}
        <div className={styles.loading}>
          <Loading display={showLoading ? 'block' : 'none'} />
        </div>
        {roteirofiltrado.map((values) => (
          <Link
            to={`/motorista/detalhe-coleta/${values?.id_oc_sub}`}
            className={values?.StatusCor == 0 ? styles.wrap_roteiro_tipo : values?.StatusCor == 1 ? styles.wrap_roteiro_tipo1 : values?.StatusCor == 2 ? styles.wrap_roteiro_tipo2 : styles.wrap_roteiro_tipo3}
            // className={1 == 1 ? `styles.wrap_roteiro_tipo` : 'styles.wrap_roteiro_tipo0'}

            // className={classNames('wrap_roteiro_tipo', {
            //   wrap_roteiro_tipo0: values?.StatusCor === 0,
            //   wrap_roteiro_tipo1: values?.StatusCor === 1,
            //   wrap_roteiro_tipo2: values?.StatusCor === 2,
            // })}
            key={values?.id_oc_sub
            }
          >
            <div style={{ width: '100%' }}>
              <ul>
                {/* <li>
                  <strong>Ordem: </strong> {values?.id_ordem}
                </li> */}
                <div className={styles.recado}>
                  <div>
                    <strong>Código:</strong> {values?.Codigo}
                  </div>
                  <div>
                    <ColetaRecado recado={values?.ColetaRecado} IDColeta={values?.IDColeta} />
                  </div>
                </div>
                <li>
                  <ExibeDataAgendamento DataAgendamento={values?.DataAgendamento} />
                </li>
                <li>
                  <strong>Marca:</strong> {values?.nome_local}
                </li>
                <li>
                  <strong>Data Ul. Coleta:</strong> {values?.DataUltimaColeta}
                </li>
                <li>
                  <strong>Endereço:</strong> {values?.Logradouro}
                </li>
                <li>
                  <strong>Bairro: </strong> {values?.Bairro}
                </li>
                <li>
                  <strong>Cidade:</strong> {values?.Cidade}
                </li>
                <li>
                  <strong>R$ Kg.: </strong>
                  <UINumber format="0.00">
                    {values?.Valorkgcontrapartida}
                  </UINumber>
                </li>
                <li>
                  <strong>QTDE: </strong>
                  <UINumber format="0.00">
                    {values?.Qtde}
                  </UINumber>
                </li>


                <ObservacaoColetaRecado recado={values?.ColetaRecado} Observacoes={values?.Observacoes} />


                {/* <li>
                  <strong>Estimativa Motorista: </strong>
                  <UINumber format="0.00">
                    {values?.quantidade_oleo_motorista}
                  </UINumber>
                </li> */}
                {/* <li>
                  <strong>Data/Hora  {values?.aviso_ocorrencia == 1 ? 'Aviso:' : 'Validação:'} </strong>
                  {values?.data_validacao}
                </li> */}
                {/* <li>
                  <strong>Status: </strong> {values?.status} {values?.aviso_ocorrencia == 1 ? ('( Aviso de Ocorrência)') : null}
                </li> */}
              </ul>
            </div>
            <div className={styles.link_to_nextPage}>
              <span>
                <i className="fas fa-arrow-right"></i>
              </span>
            </div>
          </Link>
        ))}
      </div>

    </Container >
  )
}
