import { useContext, useEffect, useState } from 'react'
import Container from '../../../components/components-ui/Container/Container'
import DataTable from 'react-data-table-component';
import styles from './painellogistica.module.css'


const columns = [
  {
    name: 'CEP',
    selector: row => row.cep,
  },
  {
    name: 'Nº Coleta',
    selector: row => row.title,
  },
  {
    name: 'Nome Local',
    selector: row => row.nome_local,
  },
];

const columns1 = [
  {
    name: 'Data',
    selector: row => row.data,
  },
  {
    name: 'Nº OC',
    selector: row => row.title,
  },
  {
    name: 'Placa',
    selector: row => row.nome_local,
  },
];

const data = [
  {
    id: 1,
    cep: '02116-001',
    title: '123',
    nome_local: 'Resaturante 01',
  },
  {
    id: 2,
    cep: '02117-001',
    title: '456',
    nome_local: 'Resaturante 02',
  },
]

function PainelLogistica() {
  return (
    <Container>
      <h3>Painel de Rotas </h3>

      <div className={styles.container_grid}>

        <header className={styles.header}>

          <rota className={styles.rota}>
            <filtro>
              <p>Fitro programação rota</p>
            </filtro>

            <conteudo>

              <DataTable
                // title=" "
                columns={columns1}
                data={data}

              />
            </conteudo>

          </rota>

          <listarota className={styles.listarota}>
            <p>Listagem de coletas</p>
            <DataTable
              // title=" "
              columns={columns}
              data={data}
              selectableRows
            />
          </listarota>

        </header>


        <footer className={styles.footer}>
          <estimativageral className={styles.estimativageral}>
            <p>Estimativa Geral</p>
            <DataTable
              // title=" "
              columns={columns}
              data={data}

            />
          </estimativageral>

          <materiais className={styles.materiais}>
            <p>Materiais</p>
            <DataTable
              // title=" "
              columns={columns}
              data={data}

            />
          </materiais>

          <estimativamotorista className={styles.estimativamotorista}>
            <p>Estimativa Motorista</p>
            <DataTable
              // title=" "
              columns={columns}
              data={data}

            />
          </estimativamotorista>
        </footer>
      </div>

    </Container >
  )
}

export default PainelLogistica
