import Container from '../../../../components/components-ui/Container/Container'
import { Link } from 'react-router-dom'
import Button from '../../../../components/components-ui/Button/Button'

function PlajementoIndicador() {
  return (
    <Container>
      <h3>Planejamento Mensal do Indicador </h3>
      <Link to="#">
        <Button title="Novo" type="button" />
      </Link>
    </Container>
  )
}

export default PlajementoIndicador
