import axios from 'axios'
import { getToken } from './auth'

const Api = axios.create({
  //baseURL: 'https://gigliobe.vbanet.com.br/',
  //baseURL: 'http://localhost:3001',
  baseURL: process.env.REACT_APP_BASE_URL, //'http://26.10.143.9:3001/',
  timeout: 99999999,
})

Api.interceptors.request.use(async (config) => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

export default Api
