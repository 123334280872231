import api from '../services/Api'
import { infoUser, login } from '../services/auth'
import { logout as exit } from '../services/auth'
import Button from '../components/components-ui/Button/Button'
//import { useClearCache } from 'react-clear-cache'
import 'react-alert-confirm/dist/index.css'
import popup from 'react-alert-confirm'
import React, { createContext, useEffect, useState } from 'react'
import jwt from 'jsonwebtoken'

export const AuthContext = createContext({})

export function AuthProvider({ children }) {
  const {
    nome_usuario,
    id_tipo_usuario,
    app_logo,
    app_nome,
    preposto,
    link_whatsapp,
    id_emite_mtr,
    id_tipo_contrapartida,
    id_local,
    id_preposto
  } = infoUser()
  const [idPreposto, setIdPreposto] = useState(id_preposto)
  const [idTipoUsuario, setIdTipoUsuario] = useState(id_tipo_usuario)
  const [appNome, setAppNome] = useState(app_nome)
  const [appLogo, setAppLogo] = useState(app_logo)
  const [idEmiteMtr, setIdEmiteMtr] = useState(id_emite_mtr)
  const [idTipoContrapartida, setIdTipoContrapartida] = useState(id_tipo_contrapartida)
  const [novaVersao, setNovaVesao] = useState(process.env.REACT_APP_VERSION)
  const [timerUpdate, setTimeUpdate] = useState(180000) //Timer do atualizador de versão
  // const { emptyCacheStorage } = useClearCache()
  const [idLocal, setIdLocal] = useState(id_local)
  // Verifica versao
  async function appVersao() {
    const response = await api.get('/getversao')
    var versaoDatabase = response.data.versao
    versaoDatabase = versaoDatabase.replace(/[^0-9]/g, '').trim()
    var versaoApp = process.env.REACT_APP_VERSION
    versaoApp = versaoApp.replace(/[^0-9]/g, '').trim()

    console.log(`versao databse${versaoDatabase}`)
    console.log(`versao app${versaoApp}`)

    if (Number(versaoDatabase) > Number(versaoApp)) {
      // setTimeUpdate(8000) //Seta o valor do timer para 24H
      // popup.confirm({
      //   title: 'Uma nova versão foi detectada, a aplicação será atualizada !',
      //   footer(dispatch) {
      //     return <Button onClick={() => dispatch('ok')}>Ok</Button>
      //   },
      // })
      //emptyCacheStorage()
      // setNovaVesao(response.data.versao)
      // let res = popup.confirm({
      //   title: 'Uma nova versão foi detectada, a aplicação será atualizada !',
      //   footer(dispatch) {
      //     return <Button onClick={() => dispatch('ok')}>Ok</Button>
      //   },
      // })

      // let updateBrowser = await res
      // if (updateBrowser[0]) {
      //   emptyCacheStorage()
      //   setNovaVesao(response.data.versao)
      // }
      // return
    }
  }
  useEffect(() => {
    appVersao()
    // eslint-disable-next-line
  }, [])
  // Tempo de verificação de atualização
  // eslint-disable-next-line
  var verifica = setInterval(() => {
    appVersao()
  }, timerUpdate)

  // Login
  async function signIn(props) {
    const dataUser = {
      nome_usuario: props.login,
      senha_usuario: props.password,
    }
    const response = await api.post('/session', dataUser)
    if (response.data.error === 0) {
      login(response.data?.token)
      setIdTipoUsuario(response.data.user?.id_tipo_usuario)
      setAppNome(response.data.user?.app_nome_fantasia)
      setIdPreposto(response.data.user?.id_preposto)
      setAppLogo(response.data.user?.app_url_logo_principal)
      setIdEmiteMtr(response.data.user?.id_emite_mtr)
      setIdTipoContrapartida(response.data.user?.id_tipo_contrapartida)
      setIdLocal(response.data.user?.id_local)
      let dataUser = jwt.sign(
        {
          appNome: response.data.user?.app_nome_fantasia,
          appLogo: response.data.user?.app_url_logo_principal,
          idEmiteMtr: response.data.user?.id_emite_mtr,
          id_tipo_contrapartida: 1//response.data.user?.id_tipo_contrapartida
        },
        'DataUser'
      )
      localStorage.setItem('user-data', dataUser)
    }
    return response
  }

  function logout() {
    exit()
    setIdTipoUsuario(null)
  }

  return (
    <AuthContext.Provider
      value={{
        logout,
        signIn,
        idTipoUsuario,
        nome_usuario,
        novaVersao,
        appLogo,
        appNome,
        preposto,
        link_whatsapp,
        idEmiteMtr,
        idTipoContrapartida,
        idLocal,
        idPreposto
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
