import * as React from 'react';
import { useContext, useEffect, useState } from 'react'


import { createTheme, ThemeProvider } from '@mui/material/styles';

import Container from '../../../../components/components-ui/Container/Container'
//import { Grid, Col } from '../../../../components/Grid'
import { Formik, Form, Field } from 'formik'
import Button from '../../../../components/components-ui/Button/Button'
import styles from './indicador.module.css'
import DataTable from 'react-data-table-component';
import api from '../../../../services/Api'

import { BlockScreenLoading } from '../../../../components/BlockScreenLoading'

import { styled } from '@mui/material/styles';
import ContainerM from '@mui/material/Container';
import Box from '@mui/material/Box';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ReactSelect from 'react-select'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';



import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import Autocomplete from '@mui/material/Autocomplete';




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function Indicador() {
    const [pending, setPending] = useState(true);
    const [Data, setData] = useState([])
    const [Auditoria, setAuditoria] = useState([])
    const [Veiculo, setVeiculo] = useState([])
    const [IdVeiculo, setIdVeiculo] = useState('')
    const [DataInicial, setDataInicial] = useState(dayjs('2023-02-01'))
    const [DataFinal, setDataFinal] = useState(dayjs('2023-02-28'))
    const [inputValue, setInputValue] = React.useState('');
    const [dataAtualServidor, setDataAtualServidor] = useState('')
    const [blockScreen, setBlockScreen] = useState(false)

    const handleChange = (event) => {
        setIdVeiculo(event.target.value);

    };

    async function getAuditoria() {
        try {
            const res_vei = await api.get(`/veiculo`)
            //console.log(res_vei.data)
            setVeiculo(res_vei.data)



            const response = await api.post(`/relatorio/auditoria_motorista`, { 'id_veiculo': IdVeiculo, 'data_inicial': dayjs(DataInicial).format('YYYY-MM-DD'), 'data_final': dayjs(DataFinal).format('YYYY-MM-DD') })

            setAuditoria(response.data)

        } catch (error) {
            console.log(error)
        }
    }




    function getRelatorio() {
        //console.log(IdVeiculo)
        getAuditoria()

    }

    // async function getMesAno() {
    //     const response = await api.get(`/mesano`)
    //     setMesAno(response.data)
    // }






    useEffect(() => {

        console.log(dayjs(DataInicial).format('YYYY-MM-DD'))

        getAuditoria()

    }, [])


    const CustomLoader = () => (
        <div style={{ padding: '24px' }}>

            <div>Carregando...</div>
        </div>
    );



    const conditionalRowStyles = [
        {
            when: row => row.Tipo === 'RESULTADO',
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ]


    const columns = [
        {
            name: 'Veículo',
            selector: row => row.Veiculo,
            id: 'Tipo',
            width: "80px",
            sortable: true,
        },
        {
            id: 'VolLigacao',
            name: 'Vol. Ligacao',
            selector: row => row.VolLigacao,
            width: "100px",
            sortable: true,
            right: true,
            grow: 1,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.VolLigacao)
            },

        },
        {
            id: 'EstimativaMotorista',
            name: 'Est. Motorista',
            right: true,
            width: "130px",
            grow: 1,
            selector: row => row.EstimativaMotorista,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.EstimativaMotorista)
            },
            sortable: true,
            // conditionalCellStyles: [
            //     {
            //         when: row => row.Granel < 0 && row.Tipo === 'RESULTADO',
            //         style: {
            //             backgroundColor: 'rgba(242, 38, 19, 0.9)',
            //             color: 'white',
            //             // '&:hover': {
            //             //     cursor: 'pointer',
            //             // },
            //         },
            //     },
            // ],

        },
        {
            id: 'PesoUsina',
            name: 'Peso Usina',
            right: true,
            selector: row => row.PesoUsina,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.PesoUsina)
            },
            sortable: true,
            width: "120px",
        },

        {
            id: 'PesoResiduo',
            name: 'Peso Resíduo',
            right: true,
            selector: row => row.PesoResiduo,
            width: "150px",
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.PesoResiduo)
            },
            sortable: true,
        },

        {
            id: 'PesoImpureza',
            name: 'Peso Impureza',
            right: true,
            width: "120px",
            selector: row => row.PesoImpureza,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.PesoImpureza)
            },
            sortable: true,
        },

        {
            id: 'TotalImpureza',
            name: 'Total Impureza',
            right: true,
            selector: row => row.TotalImpureza,
            width: "120px",
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.TotalImpureza)
            },
            sortable: true,
        },

        {
            name: 'Acur. Ligação/Mot',
            right: true,
            width: "130px",
            selector: row => row.AcuracidadeLigacaoMotorista,
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.AcuracidadeLigacaoMotorista)
            },
            sortable: true,
        },

        {
            name: 'Acur. Mot/Usina',
            right: true,
            selector: row => row.AcuracidadeUsinaMotorista,
            width: "130px",
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.AcuracidadeUsinaMotorista)
            },
            sortable: true,
        },

        {
            grow: 0,
            name: 'Acur. Mot sem impureza',
            right: true,
            selector: row => row.AcuracidadeMotoristaDescontado,
            width: "190px",
            format: (row, index) => {
                return Intl.NumberFormat('pt-BR').format(row.AcuracidadeMotoristaDescontado)
            },
            sortable: true,
        },
    ];

    const customStyles = {

        subHeader: {
            style: {
                paddingLeft: '0px', // override the cell padding for data cells
                paddingRight: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '0px',
                paddingRight: '0px',
            },
            draggingStyle: {
                cursor: 'move',
            },
        },
        head: {
            style: {
                paddingLeft: '0px', // override the cell padding for data cells
                paddingRight: '0px',
            },
        },
        header: {
            minHeight: '20px',
            zIndex: 0,
            paddingLeft: '0px', // override the cell padding for data cells
            paddingRight: '0px',
        },
        headRow: {
            style: {
                minHeight: '20px',
                zIndex: 0,
                paddingLeft: '0px', // override the cell padding for data cells
                paddingRight: '0px',
            }
        },
        cells: {
            style: {
                paddingLeft: '0px', // override the cell padding for data cells
                paddingRight: '0px',
            },
        },
        rows: {
            style: {
                minHeight: '20px', // override the row height
                paddingLeft: '0px', // override the cell padding for data cells
                paddingRight: '0px',
            },
        },
    }


    return (

        <Container>
            <p>Auditoria Motorista</p>

            <Grid container className={styles.filtro}>
                <Grid item xs={3} >
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Veículo</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={IdVeiculo}
                            label="Veículo"
                            onChange={handleChange}
                        >
                            <MenuItem value={''}>{'Selecione'}</MenuItem>
                            {Veiculo.map((values) => (

                                <MenuItem value={values?.IDVeiculo}>{values?.placa}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>


                </Grid>
                <Grid item xs={1} >

                </Grid>

                <Grid item xs={2} >

                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Data Inicial"
                                inputFormat="DD/MM/YYYY"
                                value={DataInicial}
                                onChange={(newValue) => {
                                    setDataInicial(newValue);
                                    //console.log(DataInicial)
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={1} >

                </Grid>
                <Grid item xs={2} >



                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Data Final"
                                inputFormat="DD/MM/YYYY"
                                value={DataFinal}
                                onChange={(newValue) => {
                                    setDataFinal(newValue);
                                    //console.log(DataInicial)
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>


                </Grid>
                <Grid item xs={1} >

                </Grid>

                <Grid item xs={2}>
                    <Button
                        onClick={() => getRelatorio()}
                    //disabled={status}
                    >Gerar Relatório</Button>
                </Grid>


            </Grid >




            <div className={styles.container_grid}>


                <div className={styles.coletas}>

                    <Box sx={{ width: '100%' }}>
                        <DataTable
                            // title="Indicador Diário Janeiro/2023"
                            columns={columns}
                            data={Auditoria}
                            // pagination
                            // paginationPerPage={11}
                            //dense
                            fixedHeader
                            // progressPending={pending}
                            //progressComponent={<CustomLoader />}
                            customStyles={customStyles}

                        />
                    </Box>


                </div>


            </div>


        </Container >

    )
}

export default Indicador
