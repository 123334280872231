import styles from './DetalhesColetaProdutos.module.css'
import Container from '../../../components/components-ui/Container/Container'
import Button from '../../../components/components-ui/Button/Button'
import api from '../../../services/Api'
// import Loading from '../../../components/components-ui/Loading/Loading'
import { BlockScreenLoading } from '../../../components/BlockScreenLoading'

import convert from 'image-file-resize'
import { Link } from 'react-router-dom'
import { useHistory, useParams } from 'react-router'
import { useEffect, useState, Fragment } from 'react'
import 'react-alert-confirm/dist/index.css'
import popup from 'react-alert-confirm'

// import { ToastContainer, toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'

function DetalhesColetaNaoTemOleo() {
  const history = useHistory()
  const { id } = useParams()
  const [infoDetalheColeta, setInfoDetalheColeta] = useState([])
  const [valorKG, setValorKG] = useState('')
  const [valorDinheiro, setValorDinheiro] = useState('')
  const [observacao, setObservacao] = useState('')
  // const [showLoading, setShowLoading] = useState(false)
  const [img, setImg] = useState()
  const [previewPhoto, setPreviewPhoto] = useState()
  const [listaDeImagens, setListaDeImagens] = useState([])
  const [blockScreen, setBlockScren] = useState(false)
  const [idColeta, setIdColeta] = useState('')
  const [idOcSub, setIdOcSub] = useState('')
  const [idOc, setIdOc] = useState('')
  const [formaDePagamento, setFormadepagamento] = useState([])
  const [produtos, setProdutos] = useState([])
  const [produtosCadastro, setProdutosCadastro] = useState([])
  const [preColeta, setPreColeta] = useState([])

  const [observacaoNaoTemOleo, setObservacaoNaoTemOleo] = useState([])



  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event) => {
    setSelectedOption({ idProd: event.target.value, text: event.target.options[event.target.selectedIndex].text });
  };


  function back() {

    history.push(`/motorista/detalhe-coleta/${id}`)
  }


  async function getFormaDePagamento() {
    const response = await api.get(
      `/formadepagamento`
    )
    setFormadepagamento(response.data)
  }

  async function getFormaDePagamento() {
    const response = await api.get(
      `/formadepagamento`
    )
    setFormadepagamento(response.data)
  }

  async function getProdutos() {
    const response = await api.get(
      `/produtos`
    )
    console.log(response.data)
    setProdutos(response.data)
  }



  async function getPreColeta(id) {
    const response = await api.get(
      `/precoleta/show/${id}`
    )

    setPreColeta(response.data)
    // if (response.data.length > 0) {
    //   setValorKG(response.data.VolumeOleoColetado)
    // }

  }




  async function getProdutosCadastrados(id) {

    const response = await api.get(
      `/coletaocsub/produto/${id}`
    )
    console.log(response.data)
    setProdutosCadastro(response.data)
  }


  useEffect(() => {
    async function getDetalheColeta() {
      const response = await api.post(`/motorista/roteirodetalhecoleta/${id}`)
      setInfoDetalheColeta(response.data)
      // setIdColeta(response.data[0].id_oc)
      setIdOcSub(response.data[0].id_oc_sub)
      setIdOc(response.data[0].id_oc)
      // setShowLoading(false)
      getPreColeta(response.data[0].id_oc_sub)
      // getPreColeta(response.data[0].id_oc_sub)
      getProdutosCadastrados(id)
    }

    getDetalheColeta()
    getProdutos()


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])




  async function getImagensColeta() {
    setBlockScren(true)
    let id_oc_sub = infoDetalheColeta[0]?.id_oc_sub
    if (id_oc_sub !== undefined) {
      const response = await api.post(
        `motorista/roteirodetalhecoletaimg/${id_oc_sub}`
      )
      setBlockScren(false)
      return setListaDeImagens(response.data)
    }
  }



  async function salvarColetaNaoTemOleo() {

    if (observacaoNaoTemOleo.length == 0) {
      return popup.alert({
        title: 'Informe  uma observação !!!',
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Fechar</Button>
        },
      })
    }


    if (observacaoNaoTemOleo.length <= 5) {
      return popup.alert({
        title: 'Detalhe melhor sua observação !!!',
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Fechar</Button>
        },
      })
    }



    const data = {
      observacaoNaoTemOleo: observacaoNaoTemOleo,
      idOcSub: idOcSub
    }

    console.log(data)

    const response = await api.put(`motorista/coletaNaoTemOleo/${idOcSub}`, data)
    if (response.data.error === 1) {

      return popup.alert({
        title: 'Erro ao salvar',
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Fechar</Button>
        },
      })
    }

    /// volta para a tela anterior
    history.push(`/motorista/listagem-de-coletas/${idOc}`)

  }


  async function submitPhoto() {
    setBlockScren(true)
    let id_oc_sub = infoDetalheColeta[0]?.id_oc_sub
    const formData = new FormData()
    const resizeImg = convert({
      file: img,
      //width: 800,
      //height: 600,
      type: 'jpeg',
    })
    let minImg = await resizeImg
    formData.append('file', minImg)
    const response = await api.post(
      `/motoristauploadcoleta?id_oc_sub=${id_oc_sub}`,
      formData
    )
    setBlockScren(false)
    if (response.data.error === 0) {
      setImg(null)
      getImagensColeta()
      return popup.alert({
        title: response.data.status,
        footer(dispatch) {
          return <Button onClick={() => dispatch('ok')}>Fechar</Button>
        },
      })
    }
    setImg(null)
    return popup.alert({
      title: response.data.status,
      footer(dispatch) {
        return <Button onClick={() => dispatch('ok')}>Fechar</Button>
      },
    })
  }

  function alertadelecao(id) {
    popup.alert({
      title: 'Deseja deletar o registro?',
      footer(dispatch) {
        return (
          <>
            <Button onClick={() => deleteitem(id)}>Sim</Button>
            <Button onClick={() => dispatch('ok')}>Não</Button>
          </>
        )

      },
    })
  }

  async function deleteitem(id) {


    const response = await api.put(
      `/coletaocsub/produto/delete/${id}`
    )


    let newTodos = produtosCadastro.filter(item => item.Id !== id)
    // newTodos.splice(produtosCadastro.indexOf(id), 1)


    setProdutosCadastro(newTodos)


    return popup.alert({
      title: 'Registro deletado com sucesso!',
      footer(dispatch) {
        return <Button onClick={() => dispatch('ok')}>Fechar</Button>
      },
    })
  }


  function previewImg(ev) {
    setImg(ev.target.files[0])
    const url = URL.createObjectURL(ev.target.files[0])
    setPreviewPhoto(url)
  }
  useEffect(() => {
    //getImagensColeta()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoDetalheColeta])

  console.log(produtosCadastro)
  return (
    <Container>
      <BlockScreenLoading loading display={blockScreen}>
        <strong> Carregando... </strong>
      </BlockScreenLoading>

      <div className={styles.content}>
        <div className={styles.btn_footer}>
          <Link to="#" onClick={back} className={styles.backButton}>
            <i className="fas fa-arrow-left"></i> Voltar
          </Link>
        </div>
        {infoDetalheColeta.map((values, index) => (
          <Fragment key={index}>
            <h3>Informar que o fornecedor Não tem óleo {values?.id_coleta}</h3>
            <section className={styles.wrap_coleta}>
              {/* Listagem */}
              <ul>
                <li>
                  <strong>Codigo</strong>: {values?.Codigo}
                </li>

                <li>
                  <strong>Marca</strong>: {values?.nome_local}
                </li>

              </ul>
              <div className={styles.detalhe_coleta}>
                {/* Formulario */}
                <form
                  enctype="multipart/form-data"
                  onSubmit={(ev) => ev.preventDefault()}
                >
                  {/* {values?.bloqueado === 0 ? ( */}
                  <>


                    <textarea
                      placeholder="Observação"
                      value={observacaoNaoTemOleo}
                      onChange={(e) => setObservacaoNaoTemOleo(e.target.value)}
                      style={{ marginTop: 10 }}
                    />

                    <section className={styles.wrap_btn_coleta}>
                      <Button color='tertiary' title="Salvar Coleta Não Tem Óleo" onClick={salvarColetaNaoTemOleo} />
                    </section>






                    {/* Lista de produtos */}


                  </>
                  {/* ) : null} */}

                  {values?.bloqueado === 1 ? (
                    <span>
                      {/* <strong>Aviso de ocorrência: </strong> */}
                      {values?.descricao_ocorrencia}
                    </span>
                  ) : null}

                  {/*  */}
                  {/* <div className={styles.imgBtn}>
                    <label className={styles.labelForImg} htmlFor="selectImg">
                      <i className="fas fa-camera"></i>
                      Capturar Imagem
                    </label>
                    <input
                      id="selectImg"
                      className={styles.selectImg}
                      type="file"
                      onChange={(ev) => previewImg(ev)}
                      accept="image/*"
                    />
                  </div> */}
                  {/*  */}
                </form>
                {img ? (
                  <div className={styles.previewImg}>
                    <img src={previewPhoto} alt="Preview" width="100%" />
                    <Button
                      color="secondary"
                      title="Salvar Captura"
                      onClick={submitPhoto}
                    />
                  </div>
                ) : null}
              </div>
            </section>
          </Fragment>
        ))}



        {listaDeImagens.length !== 0 ? (
          <section className={styles.wrap_coleta}>
            {listaDeImagens.map((values) => (
              <img
                src={values?.image_url}
                key={values?.id}
                alt="Photos"
                width="100%"
              />
            ))}
          </section>
        ) : null}
      </div>
    </Container>
  )
}

export default DetalhesColetaNaoTemOleo
